import { TemplateProps } from '@govtechsg/decentralized-renderer-react-components'
import { FlexBox } from 'components/flexbox'
import { A4HeightPx, A4WidthPx } from 'components/paper-size'
import { ScalableDocument } from 'components/scalable-document/ScalableDocument'
import { useShrinkToViewport } from 'hooks/useShrinkToViewport'
import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import commonImagesMap from '../common/assets/__generated__/images-map'
import { Typography } from '../common/components'
import { ObsCertMainPage } from '../common/obs-cert-main-page/obs-cert-main-page'
import { NationalYouthCouncilObsCoachingOaDoc } from './obs-coaching.types'

const TemplateContainer = styled.div`
    pre {
        background-color: lightgray;
        overflow-wrap: anywhere;
        white-space: break-spaces;
    }
`

const documentWidth = A4WidthPx
const documentHeight = A4HeightPx

export const NationalYouthCouncilObsCoachingTemplate: FunctionComponent<
    TemplateProps<NationalYouthCouncilObsCoachingOaDoc> & { className?: string }
> = ({ document, className = '' }) => {
    const name = document.name.toUpperCase()
    const issueDate = DateTime.fromISO(document.issueDate).toFormat(
        'dd MMMM yyyy'
    )
    const expiryDate = DateTime.fromISO(document.issueDate)
        .plus({
            days: -1,
            years: Number(document.validityDurationInYears),
        })
        .toFormat('dd MMMM yyyy')

    const transformScale = useShrinkToViewport(documentWidth)
    return (
        <>
            <Helmet>
                <title>national-youth-council - obs-coaching</title>
            </Helmet>
            <TemplateContainer
                className={className}
                id="national-youth-council-obs-coaching"
            >
                <ScalableDocument
                    $scale={transformScale}
                    $documentHeight={documentHeight}
                >
                    <ObsCertMainPage
                        title={
                            <Typography
                                $textAlign="center"
                                $size={'xlarge'}
                                $bold
                            >
                                Certificate of Appointment
                            </Typography>
                        }
                        signatures={[
                            {
                                signatureSrc:
                                    commonImagesMap[
                                        document.organisationRepSignature
                                    ],
                                name: document.organisationRepName,
                                title: document.organisationRepTitle,
                            },
                        ]}
                    >
                        <FlexBox $vertical>
                            <Typography $size={'large'} $mt={0}>
                                This is to certify that
                            </Typography>
                            <Typography $size={'xlarge'} $bold $mt={1}>
                                {name}
                            </Typography>
                            <Typography $size={'large'} $mt={1}>
                                is appointed as a
                            </Typography>
                            <Typography
                                $size={'xlarge'}
                                $bold
                                $mt={1}
                                $textAlign={'center'}
                            >
                                Outward Bound Singapore:
                                <br />
                                {document.courseTitle}
                            </Typography>
                            <Typography $size={'large'} $mt={1}>
                                On the date of
                            </Typography>
                            <Typography
                                $size={'xlarge'}
                                $bold
                                $mt={1}
                                $textAlign={'center'}
                            >
                                {issueDate}
                            </Typography>
                            <Typography
                                $size={'large'}
                                $mt={1}
                                $textAlign="center"
                            >
                                Certificate is valid till <b>{expiryDate}</b>
                                <br />
                                Certificate #: {document.certificateNumber}
                            </Typography>
                        </FlexBox>
                    </ObsCertMainPage>
                </ScalableDocument>
            </TemplateContainer>
        </>
    )
}
